import React from "react"
import { Grid, Button } from "@material-ui/core"
import { Link } from "react-router-dom"

import useStyles from "../styles/BuildingStyles"

function HomePage() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container alignContent="center" direction="column">
                <label className={classes.BoxLabelA}> US BUILDING 100 </label>
                <Grid
                    container
                    align="center"
                    className={classes.HomePageButtons}
                >
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/ben-nevis"}
                                className={classes.LinkButtons}
                            >
                                BEN NEVIS
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/everest"}
                                className={classes.LinkButtons}
                            >
                                EVEREST
                            </Link>
                        </Button>
                    </Grid>
                </Grid>

                <label className={classes.BoxLabelB}> US BUILDING 120 </label>
                <Grid container className={classes.HomePageButtons}>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link to={"/eiger"} className={classes.LinkButtons}>
                                EIGER
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link to={"/k2"} className={classes.LinkButtons}>
                                K2
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/killington-peak"}
                                className={classes.LinkButtons}
                            >
                                Killington Peak
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/mount-marcy"}
                                className={classes.LinkButtons}
                            >
                                Mount Marcy
                            </Link>
                        </Button>
                    </Grid>
                </Grid>

                <label className={classes.BoxLabelC}> US BUILDING 170 </label>
                <Grid container className={classes.HomePageButtons}>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/matterhorn"}
                                className={classes.LinkButtons}
                            >
                                Matterhorn
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/mont-blanc"}
                                className={classes.LinkButtons}
                            >
                                Mont Blanc
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/pikes-peak"}
                                className={classes.LinkButtons}
                            >
                                Pikes Peak
                            </Link>
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/training-room"}
                                className={classes.LinkButtons}
                            >
                                Training Room
                            </Link>
                        </Button>
                    </Grid>
                </Grid>
                <label className={classes.BoxLabelD}> SWITZERLAND </label>
                <Grid container className={classes.HomePageButtons}>
                    <Grid
                        item
                        xs={6}
                        align="center"
                        className={classes.GridButton}
                    >
                        <Button variant="contained">
                            <Link
                                to={"/chasseral"}
                                className={classes.LinkButtons}
                            >
                                Chasseral
                            </Link>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default HomePage
