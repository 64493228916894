import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import notificationIcon from "../../resources/images/notificationIcon.png"
import expand from "../../resources/images/expand.png"
import useStyles from "../styles/FaqStyles"

function TVWirelessCastFAQ({ connectName, problemTitle }) {
    const classes = useStyles()

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    {problemTitle
                        ? problemTitle
                        : "Unable to wireless cast from laptop"}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="On your personal laptop, navigate to the Notification icon, located at the bottom right corner of your computer screen, shown below outlined in red." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={notificationIcon}
                            alt="notifications"
                            className={classes.NotifIcon}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Click <b>Expand</b> to view the full list of
                                    options.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={expand}
                            alt="expand"
                            style={{ width: "85%", height: "85%" }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Select the <b>Connect</b> option. Then,
                                    choose "<u>{connectName}</u>" to cast your
                                    computer content to display onto the TV
                                    screen.
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVWirelessCastFAQ
