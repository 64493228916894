import { makeStyles } from "@material-ui/core/styles"

import "./title.css"

const font = "'Noto Sans TC', sans-serif"

const useStyles = makeStyles({
    titleText: {
        fontFamily: font,
        fontSize: "20px",
        minHeight: "37px"
    },
    locationIcon: {
        color: "#9e9e9e",
        marginLeft: "20px"
    },
    locationText: {
        fontFamily: font,
        fontSize: "14px",
        color: "#0E0E0F"
    },
    divDivider: {
        paddingTop: "9px"
    }
})

export default useStyles
