import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

function TVWirelessBuffer() {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    Wireless casting application buffering
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div
                    style={{
                        fontSize: "11px",
                        paddingLeft: "6px",
                        color: "grey"
                    }}
                >
                    — TV screen shows wireless casting application buffering,
                    but laptop content is unable to connect.
                </div>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Restart the computer connected (displaying) to the TV." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    When computer turns back on, log in with
                                    Training Room credentials, open the wireless
                                    casting application (<i>Connect</i>), and
                                    connect from your personal laptop.
                                </>
                            }
                            secondary={
                                <>
                                    [ Refer to above solutions for detailed
                                    steps. ]
                                </>
                            }
                            secondaryTypographyProps={{
                                style: { fontSize: "12px" }
                            }}
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVWirelessBuffer
