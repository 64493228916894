import { React, useState } from "react"
import { Grid } from "@material-ui/core"

import useStyles from "../styles/BuildingStyles"
import Title from "../parts/Title"
import CategoryButton from "../parts/CategoryButton"
import InputForm from "../parts/InputForm"
import FAQDialogWrapper from "../parts/FAQDialogWrapper"

function PikesPeak() {
    const classes = useStyles()
    const [helpInfo, setHelpInfo] = useState({
        location: "Pikes Peak",
        category: "",
        building: "170"
    })
    const [clicked, setClicked] = useState({
        "TV": false,
        "Phone/Audio": false,
        "Internet": false,
        "Computer": false,
        "Other": false
    })

    const handleButtonClick = (category) => {
        setHelpInfo({ ...helpInfo, category: category })
        if (category === "TV") {
            setClicked({
                "TV": true,
                "Phone/Audio": false,
                "Internet": false,
                "Computer": false,
                "Other": false
            })
        }
        if (category === "Phone/Audio") {
            setClicked({
                "TV": false,
                "Phone/Audio": true,
                "Internet": false,
                "Computer": false,
                "Other": false
            })
        }
        if (category === "Internet") {
            setClicked({
                "TV": false,
                "Phone/Audio": false,
                "Internet": true,
                "Computer": false,
                "Other": false
            })
        }
        if (category === "Computer") {
            setClicked({
                "TV": false,
                "Phone/Audio": false,
                "Internet": false,
                "Computer": true,
                "Other": false
            })
        }
        if (category === "Other") {
            setClicked({
                "TV": false,
                "Phone/Audio": false,
                "Internet": false,
                "Computer": false,
                "Other": true
            })
        }
    }

    return (
        <div className={classes.root}>
            <Title location="Pikes Peak" />
            <Grid container className={classes.ButtonGrid}>
                <CategoryButton
                    handleCategoryButtonClick={handleButtonClick}
                    clickedProp={clicked["TV"]}
                    category={"TV"}
                />
                <CategoryButton
                    handleCategoryButtonClick={handleButtonClick}
                    clickedProp={clicked["Phone/Audio"]}
                    category={"Phone/Audio"}
                />
                <CategoryButton
                    handleCategoryButtonClick={handleButtonClick}
                    clickedProp={clicked["Internet"]}
                    category={"Internet"}
                />
                <CategoryButton
                    handleCategoryButtonClick={handleButtonClick}
                    clickedProp={clicked["Computer"]}
                    category={"Computer"}
                />
                <CategoryButton
                    handleCategoryButtonClick={handleButtonClick}
                    clickedProp={clicked["Other"]}
                    category={"Other"}
                />
            </Grid>

            {clicked["TV"] && (
                <FAQDialogWrapper chosen={helpInfo} clickedObj={clicked} />
            )}

            <Grid container className={classes.ButtonGrid}>
                <InputForm infoChosen={helpInfo} />
            </Grid>
        </div>
    )
}

export default PikesPeak
