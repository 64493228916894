import React from "react"
import { Toolbar, Divider, Typography, Grid } from "@material-ui/core"
import LocationOnIcon from "@mui/icons-material/LocationOn"

import useStyles from "./styles/TitleStyles"

function Title({ location }) {
    const classes = useStyles()

    return (
        <div>
            <Toolbar className={classes.titleText}>What can we help you with?</Toolbar>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <LocationOnIcon className={classes.locationIcon} />
                <Typography className={classes.locationText}>{location} Conference Room</Typography>
            </Grid>
            <div className={classes.divDivider}>
                <Divider />
            </div>
        </div>
    )
}

export default Title
