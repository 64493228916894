import React from "react"
import { Grid, Button } from "@material-ui/core"

import useStyles from "./styles/ButtonStyles"

function CategoryButton({ handleCategoryButtonClick, clickedProp, category }) {
    const classes = useStyles()

    return (
        <Grid item xs={6} sm={4} align="center">
            <Button
                variant="outlined"
                className={classes.CategoryButton}
                onClick={(event) => handleCategoryButtonClick(category)}
                color={clickedProp ? "secondary" : "primary"}
            >
                {category}
            </Button>
        </Grid>
    )
}

export default CategoryButton
