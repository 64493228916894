import { createTheme, ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles"
import { CssBaseline } from "@material-ui/core"
import { HashRouter as Router, Route, Routes } from "react-router-dom"

import "../../resources/fonts/fonts.css"
import Navigation from "./Navigation"
import Footer from "./Footer"
import HomePage from "./HomePage"
import BenNevis from "../building100/BenNevis"
import Everest from "../building100/Everest"
import Eiger from "../building120/Eiger"
import K2 from "../building120/K2"
import KillingtonPeak from "../building120/KillingtonPeak"
import MountMarcy from "../building120/MountMarcy"
import MatterHorn from "../building170/MatterHorn"
import MontBlanc from "../building170/MontBlanc"
import PikesPeak from "../building170/PikesPeak"
import TrainingRoom from "../building170/TrainingRoom"
import Chasseral from "../buildingPCH/Chasseral"
import SubmittedForm from "../parts/SubmittedForm"

function App() {
    let palette = {
        primary: { main: "#717073" },
        secondary: { main: "#DF7A00" }
    }

    const theme = createTheme({
        palette,
        typography: {
            fontFamily: ["Century !important"].join(",")
        }
    })

    const ComponentPage = (page) => {
        return (
            <>
                <div style={{ height: "8vh" }}>
                    <Navigation />
                </div>
                <div style={{ minHeight: "88.65vh" }}>{page}</div>
                <div style={{ height: "3vh" }}>
                    <Footer />
                </div>
            </>
        )
    }

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <Router>
                <CssBaseline />
                <Routes>
                    <Route path="/" exact element={ComponentPage(<HomePage />)} />
                    <Route path="/ben-nevis" exact element={ComponentPage(<BenNevis />)} />
                    <Route path="/everest" exact element={ComponentPage(<Everest />)} />
                    <Route path="/eiger" exact element={ComponentPage(<Eiger />)} />
                    <Route path="/k2" exact element={ComponentPage(<K2 />)} />
                    <Route path="/killington-peak" exact element={ComponentPage(<KillingtonPeak />)} />
                    <Route path="/mount-marcy" exact element={ComponentPage(<MountMarcy />)} />
                    <Route path="/matterhorn" exact element={ComponentPage(<MatterHorn />)} />
                    <Route path="/mont-blanc" exact element={ComponentPage(<MontBlanc />)} />
                    <Route path="/pikes-peak" exact element={ComponentPage(<PikesPeak />)} />
                    <Route path="/training-room" exact element={ComponentPage(<TrainingRoom />)} />
                    <Route path="/chasseral" exact element={ComponentPage(<Chasseral />)} />
                    <Route path="/success" exact element={ComponentPage(<SubmittedForm />)} />
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App
