import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import windowsIcon from "../../resources/images/windowsButton.png"

function TVWirelessAppFAQ() {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    Wireless casting application undetected
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Ensure the PC connected to the TV is logged
                                    into the Training Room credentials.
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            paddingLeft: "18px"
                                        }}
                                    >
                                        — If unknown, contact GDS for
                                        assistance.
                                    </div>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Click the <b>Windows icon</b>, on the bottom
                                    left corner of the TV screen. Then, type "
                                    <b>Connect</b>" into the search bar to open
                                    the casting application.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={windowsIcon}
                            alt="windows"
                            style={{ width: "85%", height: "85%" }}
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVWirelessAppFAQ
