import { makeStyles } from "@material-ui/core/styles"

//const font = "'Noto Sans TC', sans-serif"

const useStyles = makeStyles((theme) => ({
    CategoryButton: {
        minWidth: "150px",
        minHeight: "45px",
        maxWidth: "150px",
        maxHeight: "45px",
        margin: "10px"
    }
}))

export default useStyles
