import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    CheckIcon: {
        paddingTop: "50%"
    },
    ThankYou: {
        fontSize: "22px",
        fontWeight: "bold"
    },
    Sent: {
        paddingTop: "5px"
    },
    AssistSoon: {
        paddingTop: "20px",
        fontSize: "15px"
    }
}))

export default useStyles
