import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    ButtonGrid: {
        padding: "25px"
    },
    GridButton: {
        padding: "10px",
        marginTop: "15px"
    },
    HomePageButtons: {
        padding: "10px",
        border: "2px solid",
        borderColor: "rgb(177, 177, 179)",
        borderRadius: "6px",
        marginTop: "10px",
        fontFamily: ["Century !important"].join(",")
    },
    LinkButtons: {
        textDecoration: "none",
        fontWeight: "bold",
        color: "black"
    },
    BoxLabelA: {
        position: "relative",
        top: "33px",
        left: "55px + 10%",
        fontWeight: "1000",
        fontStyle: "italic",
        fontSize: "15px",
        fontFamily: ["Century !important"].join(",")
    },
    BoxLabelB: {
        position: "relative",
        top: "33px",
        left: "55px + 10%",
        fontWeight: "1000",
        fontStyle: "italic",
        fontSize: "15px",
        fontFamily: ["Century !important"].join(",")
    },
    BoxLabelC: {
        position: "relative",
        top: "33px",
        left: "55px + 10%",
        fontWeight: "1000",
        fontStyle: "italic",
        fontSize: "15px",
        fontFamily: ["Century !important"].join(",")
    },
    BoxLabelD: {
        position: "relative",
        top: "33px",
        left: "55px + 10%",
        fontWeight: "1000",
        fontStyle: "italic",
        fontSize: "15px",
        fontFamily: ["Century !important"].join(",")
    },
    root: {
        [theme.breakpoints.up("sm")]: {
            paddingLeft: "5%",
            paddingRight: "5%"
        },
        [theme.breakpoints.between(700, 800)]: {
            paddingLeft: "10%",
            paddingRight: "10%"
        },
        [theme.breakpoints.between(800, 900)]: {
            paddingLeft: "15%",
            paddingRight: "15%"
        },
        [theme.breakpoints.between(900, 1050)]: {
            paddingLeft: "20%",
            paddingRight: "20%"
        },
        [theme.breakpoints.between(1050, 1200)]: {
            paddingLeft: "25%",
            paddingRight: "25%"
        },
        [theme.breakpoints.between(1200, 1350)]: {
            paddingLeft: "27%",
            paddingRight: "27%"
        },
        [theme.breakpoints.between(1350, 1550)]: {
            paddingLeft: "30%",
            paddingRight: "30%"
        },
        [theme.breakpoints.between(1550, 1800)]: {
            paddingLeft: "33%",
            paddingRight: "33%"
        },
        [theme.breakpoints.between(1800, 2000)]: {
            paddingLeft: "35%",
            paddingRight: "35%"
        },
        [theme.breakpoints.up(2000)]: {
            paddingLeft: "37%",
            paddingRight: "37%"
        }
    }
}))

export default useStyles
