import React from "react"
import { Typography } from "@material-ui/core"
import TaskAltIcon from "@mui/icons-material/TaskAlt"

import useStyles from "./styles/SubmittedFormStyles"

function SubmittedForm() {
    const classes = useStyles()

    return (
        <>
            <Typography align="center" className={classes.CheckIcon}>
                <TaskAltIcon fontSize="large" sx={{ color: "#DF7A00" }} />
            </Typography>
            <Typography variant="h2" align="center" className={classes.ThankYou}>
                Thank you!
            </Typography>
            <Typography align="center" className={classes.Sent}>
                Your submission has been sent.
            </Typography>
            <Typography align="center" className={classes.AssistSoon}>
                Technology Services will assist you shortly.
            </Typography>
        </>
    )
}

export default SubmittedForm
