import dotenv from "dotenv"
dotenv.config()

let backendURL, applicationID

if (process.env.REACT_APP_ENV === "development") {
    backendURL = "http://localhost:5001"
    applicationID = 31
}
if (process.env.REACT_APP_ENV === "gcpdevelopment") {
    backendURL = "https://email-server-tdnsdphoqq-uk.a.run.app"
    applicationID = 4
}
if (process.env.REACT_APP_ENV === "test") {
    backendURL = "https://email-server-b34iv6u2nq-uk.a.run.app"
    applicationID = 5
}
if (process.env.REACT_APP_ENV === "production") {
    backendURL = "https://email-server-jaf747essq-uk.a.run.app"
    applicationID = 4
}

export { backendURL, applicationID }
