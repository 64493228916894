import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    NotifIcon: {
        [theme.breakpoints.up("xs")]: {
            marginLeft: "15%"
        },
        [theme.breakpoints.up("sm")]: {
            marginLeft: "35%"
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: "45%"
        }
    },
    FAQButton: {
        position: "absolute",
        borderRadius: "15px"
    },
    BackButton: {
        marginTop: "25px",
        width: "70px",
        borderRadius: "15px"
    }
}))

export default useStyles
