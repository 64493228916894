import { React, useState } from "react"
import {
    Dialog,
    DialogTitle,
    AppBar,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material"
import { Grid, Button } from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"

import useStyles from "../styles/FaqStyles"
import TVNoSignalFAQ from "../faq/TVNoSignalFAQ"
import TVLoginEverestFAQ from "../faq/TVLoginEverestFAQ"
import TVWirelessCastFAQ from "../faq/TVWirelessCastFAQ"
import TVWirelessAppFAQ from "../faq/TVWirelessAppFAQ"
import TVWirelessBuffer from "../faq/TVWirelessBuffer"
import {
    hdmiTraditional,
    computerOnEiger,
    computerOnK2Training,
    computerOnMarcy,
    computerOnKillington,
    computerOnPikes,
    hdmiK2,
    inputK2,
    hdmi2,
    inputHdmi2,
    hdmiTraining,
    inputTraining
} from "../faq/FAQConfigConstants"

function FAQDialogWrapper({ chosen, clickedObj }) {
    const classes = useStyles()

    // key-value pair map designating the respective FAQ components for each category
    const categoryMap = {
        "TV": [
            <TVNoSignalFAQ />,
            <TVLoginEverestFAQ />,
            <TVWirelessCastFAQ />,
            <TVWirelessAppFAQ />,
            <TVWirelessBuffer />
        ],
        "Phone/Audio": [],
        "Internet": [],
        "Camera": [],
        "Computer": [],
        "Other": []
    }

    // key-value pair map designating the respective FAQ components for each building
    const locationMap = {
        "Ben Nevis": [<TVNoSignalFAQ key={1} hdmiLaptop={hdmiTraditional} />],
        "Everest": [
            <TVWirelessCastFAQ
                key={1}
                connectName={"Board Room TV"}
                problemTitle={"Direct Casting with Laptop"}
            />,
            <TVLoginEverestFAQ key={2} />
        ],
        "Eiger": [
            <TVNoSignalFAQ key={1} computerStep={computerOnEiger} />,
            <TVWirelessAppFAQ key={2} />,
            <TVWirelessCastFAQ key={3} connectName={"EigerConferenceRoom"} />,
            <TVWirelessBuffer key={4} />
        ],
        "K2": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnK2Training}
                hdmiStep={hdmiK2}
                inputStep={inputK2}
            />,
            <TVWirelessAppFAQ key={2} />,
            <TVWirelessCastFAQ key={3} connectName={"K2ConferenceRoom"} />,
            <TVWirelessBuffer key={4} />
        ],
        "Killington Peak": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnKillington}
                hdmiStep={hdmi2}
                inputStep={inputHdmi2}
            />
        ],
        "Mount Marcy": [
            <TVNoSignalFAQ key={1} computerStep={computerOnMarcy} />,
            <TVWirelessAppFAQ key={2} />,
            <TVWirelessCastFAQ
                key={3}
                connectName={"MountMarcyConferenceRoom"}
            />,
            <TVWirelessBuffer key={4} />
        ],
        "Matterhorn": [
            <TVNoSignalFAQ
                key={1}
                hdmiStep={hdmi2}
                inputStep={inputHdmi2}
                hdmiLaptop={hdmiTraditional}
            />
        ],
        "Mont Blanc": [<TVNoSignalFAQ key={1} hdmiLaptop={hdmiTraditional} />],
        "Pikes Peak": [
            <TVNoSignalFAQ key={1} computerStep={computerOnPikes} />,
            <TVWirelessAppFAQ key={2} />,
            <TVWirelessCastFAQ key={3} connectName={"PikesPeak"} />,
            <TVWirelessBuffer key={4} />
        ],
        "Training Room": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnK2Training}
                hdmiStep={hdmiTraining}
                inputStep={inputTraining}
            />,
            <TVWirelessAppFAQ key={2} />,
            <TVWirelessCastFAQ key={3} connectName={"170TrainingRoom"} />,
            <TVWirelessBuffer key={4} />
        ],
        "Chasseral": []
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    // obtain list of FAQ components that satisfy both filters (location + category)
    const getIntersectionFAQ = (chosen) => {
        let locationArray = locationMap[chosen.location]
        let categoryArray = categoryMap[chosen.category]

        // get intersection of 2 arrays
        const intersect = locationArray.filter((locationComponent) =>
            categoryArray.some(
                (categoryComponent) =>
                    categoryComponent.type.name === locationComponent.type.name
            )
        )
        return intersect
    }

    return (
        <div>
            <Grid item xs={6} sm={8} align="center">
                <Button
                    size="small"
                    variant="contained"
                    className={classes.FAQButton}
                    onClick={handleClickOpen}
                >
                    common solutions for {chosen.category}
                </Button>
            </Grid>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle sx={{ marginBottom: "30px" }}>
                    <AppBar sx={{ backgroundColor: "rgb(177, 177, 179)" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                sx={{ paddingLeft: "5px" }}
                            >
                                Common Solutions
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                {/* React.cloneElement() for key error */}
                {clickedObj[chosen.category] &&
                    getIntersectionFAQ(chosen).map(
                        (faqComponent) => faqComponent
                    )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Button
                        key={0}
                        size="small"
                        variant="contained"
                        className={classes.BackButton}
                        onClick={handleClose}
                    >
                        BACK
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}

export default FAQDialogWrapper
