import React from "react"
import { AppBar, Toolbar, Typography } from "@material-ui/core"

import logo from "../../resources/images/precipartLogoSquare.png"
import useStyles from "./styles/NavigationStyles"

/* Component that represents the header display on interface. */
function Navigation() {
    // styles classes
    const classes = useStyles()

    return (
        <div className={classes.Navigation}>
            <AppBar position="fixed" className={classes.AppBar}>
                <Toolbar>
                    {/* Precipart logo */}
                    <img alt="Precipart Logo" src={logo} className={classes.Logo} />

                    {/* Application title */}
                    <Typography variant="h6" className={classes.ApplicationName} noWrap>
                        Conference Room Tech Support
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Navigation
