import { makeStyles } from "@material-ui/core/styles"

import "./title.css"

const font = "'Noto Sans TC', sans-serif"

const useStyles = makeStyles((theme) => ({
    NameTextBox: {
        fontFamily: font,
        fontSize: "13px",
        marginLeft: "10px",
        marginTop: "20px",
        width: "65%"
    },
    AdditionalInfoTextBox: {
        marginTop: "45px",
        marginLeft: "10px",
        width: "95%"
    },
    SubmitButton: {
        marginTop: "30px",
        backgroundColor: "#DF7A00",
        color: "#FFF"
    }
}))

export default useStyles
