import { React, useEffect, useState } from "react"
import { Grid, TextField, Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import axios from "axios"

import useStyles from "./styles/InputFormStyles"
import { sendFormContents } from "../../actions/emailActions"
import { sendUserLocationInfo } from "../../actions/userLocationActions"
import Alert from "./Alert"
import { applicationID } from "../../config"

const EMAIL_RECIPIENTS =
    "servicedesk@precipart.com,gdsteam@precipart0.onmicrosoft.com"

function InputForm({ infoChosen }) {
    const classes = useStyles()
    const [nameValue, setNameValue] = useState("")
    const [infoValue, setInfoValue] = useState(" ")

    const [alertFlag, setAlertFlag] = useState(false)
    const [alertText, setAlertText] = useState("")

    const [emailInfo, setEmailInfo] = useState({
        name: nameValue,
        info: infoValue,
        location: "[" + infoChosen.building + "] " + infoChosen.location,
        category: infoChosen.category
    })

    const [userLocationInfo, setUserLocationInfo] = useState({
        country: "",
        state: "",
        city: "",
        zipCode: "",
        latitude: "",
        longitude: "",
        ipAddress: "",
        applicationID: ""
    })

    useEffect(() => {
        setEmailInfo({ ...emailInfo, category: infoChosen.category })
    }, [infoChosen])

    useEffect(() => {
        async function getData() {
            await getUserData()
        }
        getData()
    }, [])

    const getUserData = async () => {
        const res = await axios.get("https://geolocation-db.com/json/")

        setUserLocationInfo({
            country: res.data["country_name"],
            state: res.data["state"],
            city: res.data["city"],
            zipCode: res.data["postal"],
            latitude: res.data["latitude"],
            longitude: res.data["longitude"],
            ipAddress: res.data["IPv4"],
            applicationID: applicationID
        })
    }

    const handleOnChangeName = (event) => {
        setNameValue(event.target.value)
        setEmailInfo({
            ...emailInfo,
            name: event.target.value,
            category: infoChosen.category
        })
    }

    const handleOnChangeInfo = (event) => {
        setInfoValue(event.target.value)
        setEmailInfo({
            ...emailInfo,
            info: event.target.value,
            category: infoChosen.category
        })
    }

    const handleAlertDialog = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertFlag(false)
    }

    const handleOnSubmit = async (event) => {
        if (emailInfo.category === "" && emailInfo.name === "") {
            setAlertFlag(true)
            setAlertText(
                "Please select a category for your issue and provide your name."
            )
            return
        } else if (emailInfo.category === "") {
            setAlertFlag(true)
            setAlertText("Please select a category for your issue.")
            return
        } else if (emailInfo.name === "") {
            setAlertFlag(true)
            setAlertText("Please provide your name.")
            return
        }
        const emailInfoObject = {
            emailList: EMAIL_RECIPIENTS,
            emailSubject: `QRCODE HELP IN ${emailInfo.location}`,
            emailContent: `<!DOCTYPE html>
            <html>
            <head>
            </head>
            <body>
            <div>Hello Service Desk,</div>
            <br><br>
            <div style="font-weight:bold;color:orange">Someone needs your help!!</div>
            <br>
            Here's some information so you can better assist them:
            <br><br>
            <table>
                <tr>
                    <td style="font-weight:bold;border-right: 3px solid orange">WHERE? </td>
                    <td>&nbsp;&nbsp;${emailInfo.location}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold;border-right: 3px solid orange">WHO?</td>
                    <td>&nbsp;&nbsp;${emailInfo.name}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold;border-right: 3px solid orange">WHAT?</td>
                    <td>&nbsp;&nbsp;${emailInfo.category}</td>
                </tr>
                <tr>
                    <td style="font-weight:bold;border-right: 3px solid orange">MORE INFO&nbsp;</td>
                    <td><div>&nbsp;&nbsp;${emailInfo.info}</div></td>
                </tr>
            </table>
            
            <br><br>
            Good luck!
            </body>
            </html>`
        }
        try {
            await sendFormContents(emailInfoObject)
            console.log("emails successfully sent")
            await sendUserLocationInfo(userLocationInfo)
            console.log("user info successfully sent")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Your Name"
                    className={classes.NameTextBox}
                    value={nameValue}
                    onChange={handleOnChangeName}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Additional Information"
                    variant="outlined"
                    value={infoValue}
                    multiline
                    minRows={6}
                    className={classes.AdditionalInfoTextBox}
                    onChange={handleOnChangeInfo}
                />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
                <Button
                    variant="contained"
                    className={classes.SubmitButton}
                    onClick={handleOnSubmit}
                >
                    <Link
                        to={
                            emailInfo.category !== "" && emailInfo.name !== ""
                                ? "/success"
                                : ""
                        }
                        style={{ textDecoration: "none", color: "white" }}
                    >
                        SUBMIT
                    </Link>
                </Button>
                <br />
                <br />
                {alertFlag && (
                    <Alert onClose={handleAlertDialog} severity="error">
                        {alertText}
                    </Alert>
                )}
            </Grid>
        </>
    )
}

export default InputForm
